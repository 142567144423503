export default {
  'menu.welcome': 'Welcome',
  'menu.Job': 'Job',
  'menu.Job.Preview': 'Preview',
  'menu.Task': 'Task',
  'menu.Questionnaire': 'Questionnaire',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
  'menu.USContent': 'US Content',
  'menu.USContent.Moderation': 'Moderation',
  'menu.USContent.Detail': 'Moderation Detail',
  'menu.Comment': 'Comment',
  'menu.CommentModeration': 'Comment',
  'menu.CommentModeration.Task': 'Comment',
  'menu.CommentModeration.Moderation': 'Moderation',
  'menu.CommentModeration.QA': 'QA',
  'menu.DynamicAds': 'Dynamic Ads',
  'menu.DynamicAdsSampleFeed': 'Dynamic Ads Sample Feed',
  'menu.StandardAds.Tasks': 'Tasks',
  'menu.DynamicAds.ItemReview': 'Item Review',
  'menu.DynamicAds.ItemReviewSFD': 'Item Review (SFD)',
  'menu.DynamicAds.AllowDenyList': 'Allow Deny List',
  'menu.DynamicAds.AllowDenyListSFD': 'Allow Deny List (SFD)',
  'menu.StandardAds': 'Standard Ads',
  'menu.StandardAds.Task': 'Standard Ads',
  'menu.StandardAds.Manual import': 'Manual import',
  'menu.AccountProfile': 'Account Profile',
  'menu.GeoLogicAds': 'GeoLogic Ads',
  'menu.Admin': 'Admin',
  'menu.BadWord': 'Bad Word',
  'menu.Configuration': 'Configuration',
  'menu.Configuration.TaskPools': 'Task Pools',
  'menu.Configuration.CreateNewTaskPool': 'Create New Task Pool',
  'menu.Configuration.TaskPoolDetails': 'Task Pool Details',
  'menu.Configuration.TaskUserGroups': 'User Groups',
  'menu.Configuration.CreateNewUserGroup': 'Create New User Group',
  'menu.Configuration.UserGroupDetails': 'User Group Details',
};
